import { defineComponent } from 'vue'

import propsMixin from './props'

import { makeId } from '~/mixins/global'
import validationChildMixin from '~/mixins/validationChild'

export default defineComponent({
  mixins: [propsMixin, validationChildMixin],
  props: {
    hideRightPicto: {
      // overriding the propsMixin declaration to make it optional for the template child!
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['blur', 'focus', 'keyup', 'enter'],
  data() {
    return {
      focus: false,
      _uid: '',
    }
  },
  computed: {
    isDisabled(): boolean {
      return this.disabled || this.softDisabled
    },
    validationPictoIsDisplayed(): boolean {
      console.warn(
        'validationPicto display case not handled, please add a validationPictoIsDisplayed(): boolean computed in your controller ',
      )
      return true
    },
    hasPicto(): boolean {
      return (
        this.leftPicto.length > 0 || this.$slots['left-picto'] !== undefined
      )
    },
  },
  mounted(): void {
    if (this.startFocused) this.execFocus()

    this._uid = makeId()
  },
  methods: {
    execFocus(): void {
      if (import.meta.client)
        document.getElementById(this._uid.toString())?.focus()
    },
    handleKeyup(e: KeyboardEvent): void {
      if (e.key === 'Enter') this.$emit('enter', e)

      this.$emit('keyup', e)
    },
    handleBlur(e: Event): void {
      this.$nextTick(() => {
        this.focus = false
      })
      this.$emit('blur', e)
    },
    handleFocus(e: Event): void {
      this.focus = true
      this.$emit('focus', e)
    },
  },
})
